const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = 'left'

const textDecorationStyle = sample([
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy'
])

const textDecorationLine = sample([
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
])

const backgroundImageRepeat = sample([
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'space'
])

const backgroundSizes = [
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'contain',
  '8px',
  '16px',
  '24px',
  '32px',
  '64px',
  '10%',
  '25%',
  '50%',
  '75%',
  '100%',
  '200%'
]

const backgroundPositionX = sample(['left', 'center', 'right', '25%', '75%'])

const backgroundPositionY = sample(['top', 'center', 'bottom', '25%', '75%'])

const blendModes = ['normal']

const blendMode = sample(blendModes)

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

const images = [
  'http://dc28c2r6oodom.cloudfront.net/p/h/001.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/002.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/003.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/004.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/005.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/006.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/007.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/008.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/009.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/010.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/011.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/012.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/013.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/014.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/015.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/016.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/017.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/020.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/021.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/022.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/023.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/024.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/025.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/026.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/027.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/030.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/031.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/032.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/033.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/034.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/035.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/036.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/037.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/038.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/039.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/040.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/041.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/042.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/043.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/044.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/045.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/046.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/047.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/048.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/049.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/050.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/051.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/052.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/053.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/054.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/055.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/056.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/057.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/058.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/059.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/060.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/061.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/062.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/063.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/064.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/065.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/066.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/067.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/068.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/069.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/070.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/071.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/072.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/073.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/074.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/075.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/076.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/077.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/078.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/079.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/080.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/081.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/082.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/083.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/084.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/085.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/086.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/087.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/088.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/089.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/090.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/091.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/092.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/093.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/094.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/095.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/096.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/097.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/098.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/099.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/100.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/101.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/102.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/103.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/104.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/105.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/106.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/107.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/108.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/109.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/110.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/111.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/112.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/113.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/114.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/115.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/116.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/117.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/118.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/119.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/120.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/121.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/122.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/123.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/124.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/125.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/126.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/127.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/128.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/129.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/130.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/131.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/132.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/133.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/134.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/135.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/136.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/137.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/138.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/139.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/140.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/141.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/142.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/143.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/144.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/145.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/146.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/147.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/148.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/149.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/150.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/151.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/152.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/153.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/154.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/155.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/156.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/157.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/158.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/159.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/160.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/161.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/162.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/163.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/164.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/165.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/166.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/167.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/168.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/169.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/170.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/171.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/172.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/173.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/h/174.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/001.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/002.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/003.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/004.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/005.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/006.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/007.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/008.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/009.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/009.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/010.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/011.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/012.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/013.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/014.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/015.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/016.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/017.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/018.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/019.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/020.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/021.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/022.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/023.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/024.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/025.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/026.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/027.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/030.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/031.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/032.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/033.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/034.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/035.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/036.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/037.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/038.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/039.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/040.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/041.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/042.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/043.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/044.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/045.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/046.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/047.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/050.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/051.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/052.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/053.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/054.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/055.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/056.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/057.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/060.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/061.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/062.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/063.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/064.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/065.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/066.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/067.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/068.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/069.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/070.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/071.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/072.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/073.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/074.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/075.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/076.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/077.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/078.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/079.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/080.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/081.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/082.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/083.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/084.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/085.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/086.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/087.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/088.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/089.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/090.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/091.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/092.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/093.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/094.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/095.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/096.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/097.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/098.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/099.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/100.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/101.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/102.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/103.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/104.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/105.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/106.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/107.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/108.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/109.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/110.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/111.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/112.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/113.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/114.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/115.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/116.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/117.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/118.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/119.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/120.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/121.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/122.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/123.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/124.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/125.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/126.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/127.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/128.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/129.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/130.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/131.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/132.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/133.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/134.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/135.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/136.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/137.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/138.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/139.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/140.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/141.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/142.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/143.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/144.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/145.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/146.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/147.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/148.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/149.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/150.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/151.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/152.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/153.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/154.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/155.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/156.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/157.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/158.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/159.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/160.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/161.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/162.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/163.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/164.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/165.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/166.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/167.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/168.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/169.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/170.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/171.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/172.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/173.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/174.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/175.jpg',
  'http://dc28c2r6oodom.cloudfront.net/p/v/176.jpg'
]

const generateDiv = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const outerRadius = randomInt(0, 100)

  const radiusBool = randomInt(0, 10)

  const backgroundImage = sample(images)
  const backgroundSize = sample(backgroundSizes)

  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(60, 100)
    outerRadiusMin = randomInt(10, 60)
    innerRadiusMax = randomInt(50, 100)
    innerRadiusMin = randomInt(10, 60)
  }

  const borderWidth = randomInt(0, 8)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = 32

  const borderStyle = sample(borderStyles)
  const fontWeight = sample([
    100,
    200,
    300,
    400,
    400,
    400,
    400,
    500,
    500,
    500,
    600,
    600,
    600,
    700,
    700,
    700,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    900,
    900,
    900,
    900,
    900
  ])
  const fontSize = 128

  const boxSizing = sample(['border-box', 'content-box'])

  const div = {
    boxSizing: boxSizing,
    display: 'block',
    fontWeight: fontWeight,
    fontSize: fontSize,
    alignItems: 'center',
    parentBg: '#ffffff',
    bg: 'transparent', // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    height: size,
    width: size,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: padding,
    paddingRight: padding,
    paddingLeft: padding,
    paddingTop: padding,
    paddingBottom: padding,
    topRightRadius: outerRadiusMin,
    topLeftRadius: outerRadiusMax,
    bottomRightRadius: outerRadiusMax,
    bottomLeftRadius: outerRadiusMin,
    mirrorTopRightRadius: outerRadiusMax,
    mirrorTopLeftRadius: outerRadiusMin,
    mirrorBottomRightRadius: outerRadiusMin,
    mirrorBottomLeftRadius: outerRadiusMax,
    boxShadow: boxShadow,
    borderTopWidth: '',
    borderTopStyle: '',
    borderTopColor: '#000',
    borderBottomWidth: '',
    borderBottomStyle: '',
    borderBottomColor: '#ff00ff',
    borderLeftWidth: '',
    borderLeftStyle: '',
    borderLeftColor: '#ffff33',
    borderRightWidth: '',
    borderRightStyle: '',
    borderRightColor: '#ee0044',
    borderImageSource: '',
    borderImageSlice: '',
    borderImageWidth: '',
    borderImageOutset: '',
    borderImageRepeat: '',
    outlineStyle: '',
    outlineWidth: '',
    outlineColor: '',
    text: 'Rad Sparkle',
    opacity: 100,
    textAlign: 'left',
    textShadow: 'center',
    lineHeight: 100,
    letterSpacing: 0,
    textTransform: '',
    textShadowBlur: '',
    textShadowOffsetX: '',
    textShadowOffsetY: '',
    textShadowColor: '#000',
    fontStyle: '',
    whiteSpace: '',
    backgroundImage: backgroundImage,
    backgroundImageRepeat: backgroundImageRepeat,
    backgroundImageY: backgroundPositionY,
    backgroundImageX: backgroundPositionX,
    backgroundSize: backgroundSize,
    animationDelay: '',
    animationDirection: '',
    animationDuration: '',
    animationFillMode: '',
    animationIterationCount: '',
    animationName: '',
    animationPlayState: '',
    animationTimingFunction: '',
    maskClip: '',
    maskComposite: '',
    maskImage: '',
    maskMode: '',
    maskOrigin: '',
    maskRepeat: '',
    maskSize: '',
    maskType: '',
    mixBlendMode: 'normal',
    blur: 0,
    fontStyle: 'normal',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    fontFamily: 'system-ui, sans-serif'
  }

  return {
    ...div
    //backgroundImage: toGradient(gradient)
  }
}

module.exports = generateDiv
